











// This component is typically used as a dynamic component inside ModalWrapperComponent
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import * as utils_tracker from '../utils_tracker';

@Component({
  name: 'GenericConfirmationComponent',
  components: {}
})
export default class GenericConfirmationComponent extends Vue {
  // prop reactive member variables publically available to the html template

  // public reactive member variables available to the html template
  public confirmationText: string = null
  public textInput: string = null
  public placeHolderText: string = null
  public negativeButtonName: string = null;
  public positiveButtonName: string = null;

  // public functions available to the html template
  public get loading(): boolean { return this.$store.state.loading }

  public onCancel() {
    this.$emit('close', { ok: false })
  }

  public onOK() {
    this.$emit('close', { ok: true, textInput: this.textInput })
  }

  public async onShow(dynamicProps: any) {
    if (dynamicProps && dynamicProps.confirmationText) this.confirmationText = dynamicProps.confirmationText
    if (dynamicProps && dynamicProps.placeHolderText) this.placeHolderText = dynamicProps.placeHolderText

    if (dynamicProps && dynamicProps.negativeButtonName) this.negativeButtonName = dynamicProps.negativeButtonName
    if (dynamicProps && dynamicProps.positiveButtonName) this.positiveButtonName = dynamicProps.positiveButtonName
    if (!this.negativeButtonName) this.negativeButtonName = 'Cancel'
    if (!this.positiveButtonName) this.positiveButtonName = 'Ok'
  }
  

  // private, non-reactive member variables

  // private functions not available directly to HTML template
  private async mounted() { 
    if (this.$store.state.devmode) console.log(`${this.$options.name} mounted()`)

    utils_tracker.page(this.$options.name)
  }
  
  private async beforeDestroy() { if (this.$store.state.devmode) console.log(`${this.$options.name} beforeDestroy()`) }

}
